import React, { useEffect, useState } from "react";
import "./Calendar.scss";
import ReactCalendar from "react-calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { dateAlreadyClicked } from "../../screens/ScheduleScreen/ScheduleScreen";
import api from "../../utils/Api";

function Calendar({ days, onClickDay }: { days: Date[]; onClickDay: any }) {

  const [minDate, setMinDate] = useState<Date>(new Date());

  useEffect(() => {
    const checkIfOrderForNextDayIsPossible = async () => {
      try {
        const response : { status: boolean } = await api.isOrderForNextDayPossible(new Date());

        const date: Date = new Date();
        date.setTime(date.getTime() + 24 * 60 * 60 * 1000);

        if (response.status){
          setMinDate(date)
        } else {
          date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
          setMinDate(date);
        }

      } catch (err) {
        console.error(err);
      }
    }

    checkIfOrderForNextDayIsPossible();
  }, []);

  const dateSunday = (date: Date): boolean => {
    if (date.getDay() === 0) return true;
    else return false;
  }

  const tileClassName = ({ date }: { date: Date;}) => {
    if (dateAlreadyClicked(days, date)) return ['react-calendar__tile--active-state', 'react-calendar__tile'];
    if (dateSunday(date)) return ['react-calendar__tile', 'react_calendar__sunday']
    return ['react-calendar__tile'];
  }

  return <ReactCalendar minDate={minDate} tileClassName={tileClassName} onClickDay={onClickDay} prevLabel={<FontAwesomeIcon icon={faChevronLeft} />} nextLabel={<FontAwesomeIcon icon={faChevronRight} />} prev2Label={null} next2Label={null} />;
}

export default Calendar;
