import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Dispatch } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WithContext from "../../hoc/WithContext";
import { Action } from "../../reducers";
import "./BreakfastListItem.scss";
import { Breakfast, State, Variant } from "../../context/types";

export const parsePrice = (price: string): string => {
  const [bucks, pennies] = price.split(".");

  if (pennies === "00") return bucks;
  else return price;
};

function BreakfastCartItem({ breakfast, variant, dispatch }: { state: State; breakfast: Breakfast; variant: Variant; dispatch: Dispatch<Action> }) {
  const navigate = useNavigate();
  const { hotel, roomId } = useParams();

  const handleClick = () => {
    navigate(`/${hotel}/${roomId}/breakfast/${breakfast.id}/${variant.id}`);
  };

  const removeFromCart = (event: any, variant: Variant) => {
    event.stopPropagation();
    dispatch({ type: "REMOVE_FROM_CART", payload: { variantId: variant.id } });
  };

  return (
    <div
      className="breakfast-list-item"
      key={`breakfast=${breakfast.id}`}
      onClick={handleClick}
      style={{ backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,.5), rgba(0,0,0,.45)), url(${process.env.REACT_APP_SERVICE_URL}${variant.image})` }}
    >
      <div className="flex end w-100">
        <div className="breakfast-list-item-price-pill">{parsePrice(variant.price)}&nbsp;zł</div>
      </div>

      <div>
        <p className="breakfast-list-item-name">{breakfast.name}</p>
        <p className="breakfast-list-item-variant-name">{variant.option}</p>
      </div>

      <div className="flex center">
        <FontAwesomeIcon className="breakfast-list-item-delete-button" icon={faTimes} onClickCapture={(event) => removeFromCart(event, variant)} />
      </div>
    </div>
  );
}

export default WithContext(BreakfastCartItem);
