import React, { useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../context/AppContextProvider";

function WithCheckRouteValidity(Component: any) {
  return function WithCheckStepValidityRenderer(props: any): JSX.Element {
    const { step }: { step: number; } = useContext(AppContext);

    const { roomId, hotel } = useParams(); 
    const route = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (step === 1) {
            switch (route.pathname) {
                case `/${hotel}/${roomId}/adjust`:
                case `/${hotel}/${roomId}/schedule`:
                case `/${hotel}/${roomId}/summary`:
                    navigate(`/${hotel}/${roomId}`);
                    break;
            }
        }
    }, [hotel, navigate, roomId, route.pathname, step]);

    return <Component {...props} />;
  };
}

export default WithCheckRouteValidity;
