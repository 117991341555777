import { CartItem, DeliverAt } from "../context/types";
import { OrderItem } from "../screens/AdjustScreen/AdjustScreen";

class Api {
  private api: string;

  constructor() {
    this.api = `${process.env.REACT_APP_SERVICE_URL}_action_module/LarisApi`;
  }

  async getFood() {
    return await this.sendRequest("get-food", new FormData());
  }

  async beginTransaction(order: OrderItem[], hotel: string, room: string, invoice: boolean, deliverAt: DeliverAt) {
    const formData = new FormData();


    const nonZero = order.map(item => ({
      day: item.day,
      order: item.order.filter(item => item.quantity > 0)
    }));

    const strippedOrder = this.stripOrder(nonZero);

    formData.append('order', JSON.stringify(strippedOrder));
    formData.append('deliver_at', JSON.stringify(deliverAt));
    formData.append('hotel', hotel);
    formData.append('room', room);
    formData.append('invoice', invoice ? '1' : '0');

    return await this.sendRequest('submit-transaction', formData);
  }

  async getStatus(orderId: string) {
    const formData = new FormData();
    formData.append('orderId', orderId);
    return await this.sendRequest('check-status', formData);
  }

  async getClientOrder(orderId: string) {
    const formData = new FormData();
    formData.append('orderId', orderId);
    return await this.sendRequest('get-order', formData);
  }

  async checkLink(hotel: string) {
    const formData = new FormData();
    formData.append('hotel', hotel);
    return await this.sendRequest('check-link', formData);
  }

  async isOrderForNextDayPossible(date: Date) {
    const formData = new FormData();
    formData.append('timestamp', date.getTime().toString());
    return await this.sendRequest('order-possible', formData);
  }

  async sendRequest(action: string, body: FormData) {
    const request = await fetch(`${this.api}/${action}`, {
      method: "POST",
      body: body,
      headers: {
        Accept: "application/json",
      },
    });

    if (!request.ok) {
      const bad = await request.json();
      throw new Error(bad.response.error.message);
    }

    const response = await request.json();

    return response;
  }

  private stripOrder(order: OrderItem[]): {}[] {
    const strippedOrder: {}[] = [];

    order.forEach(item => {
      const { day, order } = item; 
      const strippedSubOrder: any[] = []; 

      order.forEach(cartItem => {
        const strippedBreakfast = {id: cartItem.breakfast.id, name: cartItem.breakfast.name};
        const strippedVariant = {id: cartItem.variant.id, name: cartItem.variant.name, price: cartItem.variant.price};

        strippedSubOrder.push({
          breakfast: strippedBreakfast,
          variant: strippedVariant,
          quantity: cartItem.quantity
        })
      })

      strippedOrder.push({
        day,
        order: strippedSubOrder
      });
    })

    return strippedOrder;
  }
}

const api = new Api();

export default api;