import React from 'react'  
import { faWarning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './ErrorScreen.scss'

function ErrorScreen() {
  return (
    <div className="error-screen">
      <FontAwesomeIcon icon={faWarning} />
      <p>Ładowanie aplikacji nie powiodło się.</p>
    </div>
  )
}

export default ErrorScreen