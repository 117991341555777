import React from "react";
import { Breakfast, Variant } from "../../context/types";
import './AdjustScreenTile.scss'

function AdjustScreenTile({ breakfast, variant, quantity, day, changeQuantity }: { breakfast: Breakfast; variant: Variant; quantity: number; day: Date|null; changeQuantity: Function|null; }) {
  return (
    <div className={`adjust-screen-tile bg-primary flex ${quantity === 0 ? 'bg-secondary' : ''}`}>
      <h4 className="adjust-screen-tile-breakfast-name">{variant.name}</h4>
      <p className="adjust-screen-tile-variant-price">{variant.price} <span className="currency">zł</span></p>
      <select className="adjust-screen-tile-quantity" disabled={!changeQuantity} value={quantity} onChange={changeQuantity ? (e) => changeQuantity(variant, day, e.target.value) : () => {}}>
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
      </select>
    </div>
  )
}

export default AdjustScreenTile;
