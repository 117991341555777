import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import OrderDaysList from "../../components/OrderDaysList/OrderDaysList";
import { DeliverAt, State } from "../../context/types";
import WithCheckRouteValidity from "../../hoc/WithCheckRouteValidity";
import WithContext from "../../hoc/WithContext";
import api from "../../utils/Api";
import { OrderItem } from "../AdjustScreen/AdjustScreen";
import "./../../scss/global.scss";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocation, faPhone } from "@fortawesome/free-solid-svg-icons";
import "./OrderStatusScreen.scss";

function OrderStatusScreen({ state }: { state: State }) {
  const { orderId } = useParams();
  const [paid, setPaid] = useState<boolean>(false);
  const [order, setOrder] = useState<{ order: OrderItem[]; deliverAt: DeliverAt; paymentUrl: string } | null>(null);
  const orderErrorThrown = useRef(false);
  const requestCounter = useRef(0);

  useEffect(() => {
    const statusPooling = async () => {
      const fetch = async () => {
        const result = await api.getStatus(orderId as string);
        return result;
      };

      const pool = async () => {
        try {
          const result = await fetch();

          if (!result.status && !orderErrorThrown.current && requestCounter.current <= 15) {
            requestCounter.current++;
            setTimeout(pool, 5000);
          } else if (result.status) setPaid(true);
        } catch (error) {
          toast("Wystąpił błąd. " + error, {
            type: "error",
          });
        }
      };

      pool();
    };

    const getOrder = async () => {
      try {
        const result = await api.getClientOrder(orderId as string);

        if (result.order) {
          const parsedOrder = result.order.map((item: any) => ({ day: new Date(item.day), order: item.order }));
          const parsedDeliverAt = {
            days: result.deliver_at.days.map((time: string) => new Date(time)),
            hour: result.deliver_at.hour,
          };

          setOrder({
            order: parsedOrder,
            deliverAt: parsedDeliverAt,
            paymentUrl: result.payment_url,
          });
        }
      } catch (error) {
        orderErrorThrown.current = true;
      }
    };

    statusPooling();
    getOrder();
  }, []);

  return (
    <div className="order-status-screen">
      {order ? (
        <>
          <h3 className="title">Dziękujemy za złożenie zamówienia</h3>
          <div className="flex between margin-1">
            <p>
              <b>Status płatności: </b><b className={paid ? "paid" : "not-paid"}>{paid ? "zapłacone" : "niezapłacone"}</b>
            </p>
            {!paid ? <div>
              <a className="button primary" href={order.paymentUrl}>
                Przejdź do płatności
              </a>
            </div> : null}
          </div>
          <b>Restauracja Kazimir</b>

          <div className="contact-wrapper">
            <div className="contact-column">
              <FontAwesomeIcon icon={faLocation} /> ul. Miodowa 11, 31-055 Kraków
            </div>
            <div className="contact-column">
              <FontAwesomeIcon icon={faPhone} /> <a href="tel:+48 690 624 295">+48 690 624 295</a>
            </div>
            <div className="contact-column">
              <FontAwesomeIcon icon={faEnvelope} /> <a href="mailto:info@kazimirrestaurant.pl">info@kazimirrestaurant.pl</a>
            </div>
          </div>

          <div className="order-status-order-content">
            <OrderDaysList order={order.order} deliverAt={order.deliverAt} changeQuantity={null} hideNotOrdered={true} />
          </div>
        </>
      ) : (
        <>
          <h3 className="title">Brak zamówieniu o tym id w bazie</h3>
          <p className="subtitle">Brak zamówienia o podanym id.</p>
        </>
      )}
    </div>
  );
}

export default WithContext(WithCheckRouteValidity(OrderStatusScreen));
