import { CartItem, State, Variant } from "../context/types";

class Cart {
    public inCart(state: State, variant: Variant): boolean 
    {
        const { cart }: { cart: CartItem[] } = state;

        const alreadyHere = cart.filter(item => item.variant.id === variant.id);

        if (alreadyHere.length) {
            return true;
        } else {
            return false;
        }
    }
}

const cart = new Cart();

export default cart;
