import React, { useState } from "react";
import { Breakfast, DeliverAt, Variant } from "../../context/types";
import { days, OrderItem } from "../../screens/AdjustScreen/AdjustScreen";
import Popup from "../Popup/Popup";
import AdjustScreenTile from "../AdjustScreenTile/AdjustScreenTile";
import AdjustHour from "../AdjustHour/AdjustHour";

function OrderDaysList({ order, deliverAt, changeQuantity, hideNotOrdered = true }: { order: OrderItem[]; deliverAt: DeliverAt; changeQuantity: Function | null; hideNotOrdered: boolean | null }) {
  const [adjustHourPopupShown, setAdjustHourPopupShown] = useState<boolean>(false);

  return (
    <>
      {order.map(({ day, order }: { day: Date; order: any }, j: number) => (
        <div className="adjust-single-date" key={`single-day-${j}`}>
          <div className="flex between align-center">
            <h5 className="adjust-day">
              {days[day.getDay()]} {day.getDate().toString().padStart(2, '0')}.{(day.getMonth() + 1).toString().padStart(2, '0')}.{day.getFullYear()}
            </h5>
            <p className="adjust-hour" onClick={() => setAdjustHourPopupShown(true)}>
              Godz. dostawy: <b>{deliverAt.hour === 1 ? "7:00-8:00" : "8:30-9:30"}</b>
            </p>
          </div>
          <div className="adjust-days-wrapper">
            {order.map((item: { breakfast: Breakfast; variant: Variant; quantity: number }, j: number) =>
              hideNotOrdered && item.quantity === 0 ? null : <AdjustScreenTile key={`adjust-screen-tile-${j}`} day={day} changeQuantity={changeQuantity} {...item} />
            )}
          </div>
        </div>
      ))}
      {adjustHourPopupShown ? <Popup content={<AdjustHour />} close={() => setAdjustHourPopupShown(false)} /> : null}
    </>
  );
}

export default OrderDaysList;
