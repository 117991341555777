import { CartItem, DeliverAt, State } from "../context/types";
import OrderBuilder from "../utils/OrderBuilder";

export type Action = {
  type: string;
  payload: any;
};

export const buildOrder = (deliverAt: DeliverAt, cart: CartItem[]) => {
  const builtOrder: any[] = [];

  deliverAt.days.forEach((day) => {
    builtOrder.push({ day: day, order: cart });
  });

  return builtOrder;
};

const appReducer = (state: State, action: Action): State => {
  const orderBuilder = new OrderBuilder(state.cart, state.order);

  const { payload, type } = action;

  switch (type) {
    case "SET_BREAKFASTS":
      return {
        ...state,
        breakfasts: action.payload,
      };
    case "ADD_TO_CART":
      return {
        ...state,
        cart: [...state.cart, { breakfast: payload.breakfast, variant: payload.variant, quantity: state.cart.length ? 0 : 1 }],
        order: orderBuilder.addToOrder(payload.breakfast, payload.variant)
      };
    case "REMOVE_FROM_CART":
      return {
        ...state,
        cart: state.cart.filter((cartItem: CartItem) => cartItem.variant.id !== payload.variantId),
        order: orderBuilder.removeFromOrder(payload.variantId)
      };
    case "SET_DELIVER_DAYS":
      const newState = {
        ...state,
        deliverAt: {
          days: payload,
          hour: state.deliverAt.hour,
        },
      };

      if (!state.order.length) {
        newState.order = orderBuilder.buildOrderForFirstTime(
          {
            days: payload,
            hour: state.deliverAt.hour,
          },
        );
      } else {
        newState.order = orderBuilder.alterDays(
          {
            days: payload,
            hour: state.deliverAt.hour,
          }
        );
      }

      return newState;
    case "SET_DELIVER_HOUR":
      return {
        ...state,
        deliverAt: {
          days: state.deliverAt.days,
          hour: payload,
        },
      };
    case "SET_ORDER":
      return {
        ...state,
        order: payload,
      };
    case "SET_PRICE":
      return {
        ...state,
        price: payload,
      };
  }

  return state;
};

export default appReducer;
