import React, { useMemo, useContext } from "react";
import { AppContext } from "../../context/AppContextProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./NextStepButton.scss";
import { State } from "../../context/types";
import InvoiceCheckbox from "../InvoiceCheckbox/InvoiceCheckbox";
import api from "../../utils/Api";
import { toast } from "react-toastify";

const canShowPrice = (state: State, step: number) => {
  if (state.price !== 0 && step >= 3) return true;
  else return false;
};

const canShowInvoice = (step: number) => {
  if (step === 4) return true;
  else return false;
};

export const isButtonBlocked = (state: State, step: number): boolean | undefined => {
  switch (step) {
    case 1:
      return state.cart.length < 1;
    case 2:
      return state.deliverAt.days.length < 1;
    case 3:
      return state.order.length < 1;
    case 4:
      return state.order.length < 1;
  }

  return false;
};


function NextStepButton() {
  const { state, step, setStep, recieveInvoice }: { state: State; step: number; setStep: Function; recieveInvoice: boolean } = useContext(AppContext);
  const path = useLocation();
  const { hotel, roomId } = useParams();
  const navigate = useNavigate();

  const backButtonShouldOccur = () => {
    const paths = [`/${hotel}/${roomId}/cart`];

    if (paths.includes(path.pathname)) return true;
    else return false;
  };

  const shouldShow = useMemo(backButtonShouldOccur, [path, roomId]);

  const beginTransaction = async () => {
    try {
      const response: { orderId: string; paymentUrl: string } = await api.beginTransaction(state.order, hotel as string, roomId as string, recieveInvoice, state.deliverAt);
      window.open(response.paymentUrl, '_blank');
      navigate(`/order/${response.orderId}`);
    } catch (err) {
      toast('Wystąpił błąd. Err: ' + err)
    }
  };

  const handle = async () => {
    setStep(step + 1);

    switch (step) {
      case 1:
        navigate(`/${hotel}/${roomId}/schedule`);
        break;
      case 2:
        navigate(`/${hotel}/${roomId}/adjust`);
        break;
      case 3:
        navigate(`/${hotel}/${roomId}/summary`);
        break;
      case 4:
        beginTransaction();
        break;
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="next-step-wrapper">
      {canShowPrice(state, step) ? (
        <h3 className="price-header">
          Koszt całkowity: <span className="price">{state.price}&nbsp;zł</span>
        </h3>
      ) : null}
      {/* {canShowInvoice(step) ? <InvoiceCheckbox /> : null} */}
      <div className="next-step-buttons flex gap">
        {shouldShow ? (
          <button className="button outline-primary big" onClick={goBack}>
            Wróć
          </button>
        ) : null}
        <button className="button primary big" onClick={handle} disabled={isButtonBlocked(state, step)}>
          Dalej
        </button>
      </div>
    </div>
  );
}

export default NextStepButton;
