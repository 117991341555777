import React from "react";
import BreakfastCartItem from "../../components/BreakfastListItem/BreakfastCartItem";
import { CartItem, State } from "../../context/types";
import WithContext from "../../hoc/WithContext";
import WithLayout from "../../hoc/WithLayout";
import WithNextStepButton from "../../hoc/WithNextStepButton";

function CartScreen({ state }: { state: State }) {
  return (
    <>
      <h3 className="title">Twoje wybrane śniadania</h3>
      <div className="breakfasts-screen-wrapper">
        <div className="breakfasts-screen-grid padded-list">
          {state.cart.map((cartItem: CartItem, index: number) => (
            <BreakfastCartItem key={`cart-item-${index}`} breakfast={cartItem.breakfast} variant={cartItem.variant} />
          ))}
        </div>
      </div>
    </>
  );
}

export default WithContext(WithNextStepButton(WithLayout(CartScreen)));
