import React from "react";
import './../scss/global.scss';
import CartWidget from "../components/CartWidget/CartWidget";

function WithCart(Component: any) {
  return function CartRenderer(props: any): JSX.Element {

    return (
        <>
            <CartWidget />
            <Component {...props} />
        </>
    );
  };
}

export default WithCart;
