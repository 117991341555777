import React, { Dispatch, useEffect } from "react";
import WithContext from "../../hoc/WithContext";
import WithLayout from "../../hoc/WithLayout";
import "./../BreakfastsListScreen/BreakfastsListScreen.scss";
import "./AdjustScreen.scss";
import "./../../scss/global.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import AdjustScreenTile from "../../components/AdjustScreenTile/AdjustScreenTile";
import { Action } from "../../reducers";
import WithNextStepButton from "../../hoc/WithNextStepButton";
import { Breakfast, CartItem, DeliverAt, State, Variant } from "../../context/types";
import WithCheckRouteValidity from "../../hoc/WithCheckRouteValidity";
import OrderDaysList from "../../components/OrderDaysList/OrderDaysList";

// js weekdate starts with sunday as 0 and so on
export const days = ["Nd", "Pon", "Wt", "Śr", "Czw", "Pt", "Sob"];

export type OrderItem = {
  day: Date;
  order: CartItem[];
};

const changeSpecificVariantQuantity = (array: CartItem[], variant: Variant, quantity: number): CartItem[] => {
  return array.map((item) => {
    if (item.variant.id === variant.id) {
      return {
        ...item,
        quantity: quantity,
      };
    } else {
      return item;
    }
  });
};

function AdjustScreen({ state, dispatch, sameEveryday, setSameEveryday }: { state: State; dispatch: Dispatch<Action>; sameEveryday: boolean; setSameEveryday: Function }) {
  const { deliverAt, order }: { deliverAt: DeliverAt; order: OrderItem[] } = state;

  useEffect(() => {
    const price = order.map((day) => day.order.reduce((sum: any, item: any) => (sum += item.quantity * item.variant.price), 0)).reduce((sum: number, item: number) => (sum += item), 0);
    dispatch({ type: "SET_PRICE", payload: price });
  }, [order, dispatch, sameEveryday]);

  const changeQuantity = (variant: Variant, day: Date, value: any) => {
    let changedOrder = [];

    if (!day) {
      changedOrder = order.map((date) => {
        return {
          day: date.day,
          order: changeSpecificVariantQuantity(date.order, variant, parseInt(value)),
        };
      });
    } else {
      changedOrder = order.map((single) => {
        if (single.day === day) {
          return {
            day: single.day,
            order: changeSpecificVariantQuantity(single.order, variant, parseInt(value)),
          };
        } else {
          return single;
        }
      });
    }

    dispatch({ type: "SET_ORDER", payload: changedOrder });
  };

  const handleSameEveryday = () => {
    setSameEveryday(!sameEveryday);
  };

  return (
    <div className="adjust-days-screen">
      <label htmlFor="same-everyday" className="label same-everyday">
        <input checked={sameEveryday} onChange={handleSameEveryday} type="checkbox" className="checkbox" id="same-everyday" />
        Proszę o ten sam zestaw każdego dnia.
        <FontAwesomeIcon className="icon" icon={faCheck}></FontAwesomeIcon>
      </label>

      <div className="adjust-days-list">
        {sameEveryday ? (
          <div className="adjust-days-wrapper">
            {order.length
              ? order[0].order.map((item: { breakfast: Breakfast; variant: Variant; quantity: number }, j: number) => (
                  <AdjustScreenTile key={`tile-${j}`} day={null} changeQuantity={changeQuantity} {...item} />
                ))
              : null}
          </div>
        ) : (
          <OrderDaysList order={order} deliverAt={deliverAt} changeQuantity={changeQuantity} hideNotOrdered={false} />
        )}
      </div>
    </div>
  );
}

export default WithContext(WithNextStepButton(WithLayout(WithCheckRouteValidity(AdjustScreen))));
