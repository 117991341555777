import { faWheatAwn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Dispatch, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WithContext from "../../hoc/WithContext";
import { Action } from "../../reducers";
import "./BreakfastListItem.scss";
import cart from "../../utils/Cart";
import { Breakfast, State, Variant } from "../../context/types";
import { parsePrice } from "./BreakfastCartItem";

function BreakfastListItem({ state, breakfast, dispatch }: { state: State; breakfast: Breakfast; dispatch: Dispatch<Action>; }) {
  const navigate = useNavigate();
  const { hotel, roomId } = useParams();
  const [activeVariant, setActiveVariant] = useState(1);

  const getVariant = (): Variant => {
    return breakfast.variants[activeVariant];
  };

  const variant = getVariant();

  const handleClick = () => {
    navigate(`/${hotel}/${roomId}/breakfast/${breakfast.id}/${variant.id}`);
  };

  const addToCart = (event: any, breakfast: Breakfast, variant: Variant) => {
    event.stopPropagation();
    const inCart = cart.inCart(state, variant);
    if (!inCart) {
      dispatch({ type: "ADD_TO_CART", payload: { variant: variant, breakfast: breakfast } });

      document.dispatchEvent(new Event("AddedToCart"));
    } else {
      navigate(`/${hotel}/${roomId}/cart`);
    }
  };

  const handleCheckbox = (event: any) => {
    event.stopPropagation();
    setActiveVariant(activeVariant === 0 ? 1 : 0);
  };

  const checkboxOff = activeVariant === 0 ? "off" : "";

  return (
    <div
      className="breakfast-list-item"
      key={`breakfast=${breakfast.id}`}
      onClick={handleClick}
      style={{ backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,.5), rgba(0,0,0,.45)), url(${process.env.REACT_APP_SERVICE_URL}${variant.image})` }}
    >
      <div className="flex end w-100">
        <div className="breakfast-list-item-price-pill">{parsePrice(variant.price)}&nbsp;zł</div>
      </div>

      <div className="breakfast-list-content">
        <p className="breakfast-list-item-name">{breakfast.name}</p>
        {/* <div className={`breakfast-list-checkbox ${checkboxOff}`} onClickCapture={handleCheckbox}>
          <div className="breakfast-list-checkbox-dot"></div>
          <div className="breakfast-checkbox-column no-gluten">
            <FontAwesomeIcon icon={faWheatAwn} />
          </div>
          <div className="breakfast-checkbox-column">
            <FontAwesomeIcon icon={faWheatAwn} />
          </div>
        </div> */}
        <p className="breakfast-list-item-variant-name">{variant.option}</p>
      </div>

      <div className="flex center">
          <button className="button white" onClickCapture={(event) => addToCart(event, breakfast, variant)}>
            { cart.inCart(state, variant) ? "W koszyku" : "Do koszyka" }
          </button>
      </div>
    </div>
  );
}

export default WithContext(BreakfastListItem);
