import React, { useContext } from "react";
import { ProviderValues } from "../context/types";
import ErrorScreen from "../screens/ErrorScreen/ErrorScreen";
import LoaderScreen from "../screens/LoaderScreen/LoaderScreen";
import { AppContext } from "./../context/AppContextProvider";

function WithContext(Component: any) {
  return function ContextRenderer(props: any): JSX.Element {
    const { state, dispatch, appLoading, appSettled, step, setStep, recieveInvoice, setRecieveInvoice, sameEveryday, setSameEveryday } = useContext<ProviderValues>(AppContext);

    if (appLoading) return <LoaderScreen />;

    if (!appSettled) return <ErrorScreen />;

    return (
      <Component
        sameEveryday={sameEveryday}
        setSameEveryday={setSameEveryday}
        recieveInvoice={recieveInvoice}
        setRecieveInvoice={setRecieveInvoice}
        step={step}
        setStep={setStep}
        state={state}
        dispatch={dispatch}
        appLoading={appLoading}
        appSettled={appSettled}
        {...props}
      />
    );
  };
}

export default WithContext;
