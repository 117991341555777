import React from "react";
import StepCounter from "../components/StepCounter/StepCounter";
import './../scss/global.scss';

function WithLayout(Component: any) {
  return function LayoutRenderer(props: any): JSX.Element {
    return (
      <div className="layout">
        <StepCounter />
        <Component {...props} />
      </div>
    );
  };
}

export default WithLayout;
