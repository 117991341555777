import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Dispatch, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WithContext from "../../hoc/WithContext";
import "./BreakfastScreen.scss";
import "./../../components/NextStepButton/NextStepButton.scss";
import WithCart from "../../hoc/WithCart";
import cart from "../../utils/Cart";
import { Action } from "../../reducers";
import { Breakfast, State, Variant } from "../../context/types";

function BreakfastScreen({ state, dispatch }: { state: State; dispatch: Dispatch<Action>; }) {
  const { breakfastId, variantId } = useParams<string>();
  const { hotel, roomId } = useParams();
  const navigate = useNavigate();

  if (!breakfastId || !variantId) navigate(`/${hotel}/${roomId}`);

  const [currentBreakfast, setCurrentBreakfast] = useState<Breakfast>();
  const [chosenVariant, setChosenVariant] = useState<Variant>();

  useEffect(() => {
    const currentBreakfast: Breakfast = state.breakfasts.filter((breakfast: Breakfast) => breakfast.id === parseInt(breakfastId as string))[0];

    if (currentBreakfast) {
      setCurrentBreakfast(currentBreakfast);
      if (currentBreakfast.variants.length) {
        const variant = currentBreakfast.variants.filter(variant => variant.id === parseInt(variantId as string))[0];
        setChosenVariant(variant);
      }
    }
  }, []);

  if (!currentBreakfast) return null;

  const handleVariantChange = (index: number) => {
    setChosenVariant(currentBreakfast.variants[index]);
  };

  const handleClose = () => {
    navigate(-1);
  };

  const addToCart = (event: any, breakfast: Breakfast, variant: Variant) => {
    event.stopPropagation();
    const inCart = cart.inCart(state, variant)
    if (!inCart) {
      dispatch({ type: "ADD_TO_CART", payload: { variant: variant, breakfast: breakfast } });
    } else {
      navigate(`/${hotel}/${roomId}/cart`);
    }
  };

  return (
    <div className="breakfast-screen">
      <div
        className="breakfast-image-wrapper"
        style={{ backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,.4), rgba(0,0,0,.35)), url(${process.env.REACT_APP_SERVICE_URL}${chosenVariant?.image})` }}
      >
        <h3>{currentBreakfast.name}</h3>
        <h5>{chosenVariant ? chosenVariant.option : null}</h5>
        <div className="breakfast-image-close-wrapper" onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
        </div>
      </div>
      <div className="breakfast-content-wrapper">
        {chosenVariant ? (
          <>
            <div className="breakfast-content-title-wrapper">
              <div>
                <h3>{currentBreakfast.name}</h3>

                {(chosenVariant.tags.length || chosenVariant.calories !== 0) ? (<div className="breakfast-content-additional-info">
                  <div>
                    {chosenVariant.calories !== 0 ? (<p>
                      {chosenVariant.calories}&nbsp;kcal
                    </p>): null}
                  </div>
                  <div className="breakfast-content-tag-wrapper">
                    {chosenVariant.tags.length
                      ? chosenVariant.tags.map((tag: any, j: number) => (
                          <div className="breakfast-content-tag" key={`tag-${j}`}>
                            {tag}
                          </div>
                        ))
                      : null}
                  </div>
                </div>) : null}
              </div>
              <div className="breakfast-content-price">{chosenVariant.price}&nbsp;zł</div>
            </div>
            {/* <div className="breakfast-variants-wrapper">
              {currentBreakfast.variants.map((variant: Variant, j: number) => (
                <div className={variant.id === chosenVariant.id ? `breakfast-content-variant active` : "breakfast-content-variant"} key={`variant-${j}`} onClick={() => handleVariantChange(j)}>
                  {variant.option}
                </div>
              ))}
            </div> */}
          </>
        ) : null}
        <h3 className="breakfast-content-header">Co dostaniesz</h3>
        <div className="breakfast-content-description" dangerouslySetInnerHTML={{__html: chosenVariant ? chosenVariant.description : ''}}></div>
      </div>
      <div className="next-step-wrapper">
        <button className="button primary big" onClickCapture={(event) => addToCart(event, currentBreakfast, chosenVariant as Variant)}>{cart.inCart(state, chosenVariant as Variant) ? "W koszyku" : "Dodaj do koszyka"}</button>
      </div>
    </div>
  );
}

export default WithContext(WithCart(BreakfastScreen));
