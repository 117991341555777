import React from "react";
import './../scss/global.scss';
import NextStepButton from "../components/NextStepButton/NextStepButton";

function WithNextStepButton(Component: any) {
  return function NextStepButtonRenderer(props: any): JSX.Element {

    return (
        <>
            <Component {...props} />
            <NextStepButton />
        </>
    );
  };
}

export default WithNextStepButton;
