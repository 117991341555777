import React, { useEffect, useReducer, useState } from "react";
import api from "./../utils/Api";
import appReducer from "./../reducers/index";
import { ProviderValues } from "./types";
import { toast } from "react-toastify";
import OrderBuilder from "../utils/OrderBuilder";

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const AppContext = React.createContext<ProviderValues>({} as ProviderValues);

function AppContextProvider({ children }: Props): JSX.Element {
  const [state, dispatch] = useReducer(appReducer, { breakfasts: [], cart: [], deliverAt: { days: [], hour: 1 }, order: [], price: 0 });
  const [appLoading, setAppLoading] = useState<boolean>(true);
  const [appSettled, setAppSettled] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [recieveInvoice, setRecieveInvoice] = useState<boolean>(false);
  const [sameEveryday, setSameEveryday] = useState<boolean>(true);

  useEffect(() => {
    const getFood = async () => {
      try {
        const response = await api.getFood();
        dispatch({ payload: response.breakfasts.filter((breakfast: any) => breakfast.variants.length), type: "SET_BREAKFASTS" });
        setAppSettled(true);
        setAppLoading(false);
      } catch (error) {
        toast("Wystąpił błąd podczas ładowania listy śniadań. " + error, { type: "error" });
        setAppSettled(false);
        setAppLoading(false);
      }
    };

    getFood();
  }, []);

  const handleSameEveryday = () => {
    if (!sameEveryday) {
      const orderBuilder = new OrderBuilder(state.cart, state.order);
      const order = orderBuilder.buildSameDayEveryday();
      dispatch({type: 'SET_ORDER', payload: order});
    }

    setSameEveryday(!sameEveryday);
  }

  const providerValues: ProviderValues = {
    state,
    dispatch,
    appLoading,
    appSettled,
    step,
    setStep,
    recieveInvoice,
    setRecieveInvoice,
    sameEveryday, 
    setSameEveryday: handleSameEveryday
  };

  return <AppContext.Provider value={providerValues}>{children}</AppContext.Provider>;
}

export default AppContextProvider;
