import React, { useEffect, useRef } from "react";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WithContext from "../../hoc/WithContext";
import "./CartWidget.scss";
import { useNavigate, useParams } from "react-router-dom";
import "./../../screens/BreakfastsListScreen/BreakfastsListScreen.scss";
import { State } from "../../context/types";

function CartWidget({ state }: { state: State }) {
  const { hotel, roomId } = useParams();
  const navigate = useNavigate();
  const ref: any = useRef(null);

  const openCart = () => {
    navigate(`/${hotel}/${roomId}/cart`);
  };

  useEffect(() => {


    document.addEventListener("AddedToCart", () => {
      if (ref.current) {
        ref.current.classList.add('animate');

        setTimeout(() => {
          ref.current.classList.remove('animate');
        }, 300)
      }
    });
  }, []);

  return (
    <div className="cart-widget" onClick={openCart} ref={ref}>
      <FontAwesomeIcon icon={faCartShopping}></FontAwesomeIcon>
      <div className="cart-widget-counter">{state.cart.length}</div>
    </div>
  );
}

export default WithContext(CartWidget);
