import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { State } from "../../context/types";
import WithContext from "../../hoc/WithContext";
import { isButtonBlocked } from "../NextStepButton/NextStepButton";
import "./StepCounter.scss";

function StepCounter({ state, step, setStep }: { state: State; step: number; setStep: Function }) {
  const navigate = useNavigate();
  const { hotel, roomId } = useParams();

  const steps = [
    {
      step: 1,
      name: "Wybierz śniadanie",
    },
    {
      step: 2,
      name: "Wybierz czas dostawy",
    },
    {
      step: 3,
      name: "Dopasuj wybrane posiłki",
    },
    {
      step: 4,
      name: "Zapłać",
    },
  ];

  const classRenderer = (stepCount: number): string => {
    if (stepCount === step) return "step step-current";
    if (stepCount < step) return "step step-completed";
    return "step";
  };

  const arrowClassRenderer = (stepCount: number): string => {
    if (stepCount < step) return "step-completed";

    return "";
  };

  const handleRedirect = (step: number) => {
    switch (step) {
      case 1:
        navigate(`/${hotel}/${roomId}/`);
        break;
      case 2:
        navigate(`/${hotel}/${roomId}/schedule`);
        break;
      case 3:
        navigate(`/${hotel}/${roomId}/adjust`);
        break;
    }
  }

  const handleClick = (clickedStep: number) => {
    if (clickedStep < step) {
      setStep(clickedStep);
      handleRedirect(clickedStep)
    } else {
      const isStepBlocked = isButtonBlocked(state, clickedStep);

      if (!isStepBlocked) {
        setStep(clickedStep)
        handleRedirect(clickedStep)
      }
    }
  };

  return (
    <div className="step-counter">
      {steps.map((singleStep, j) => (
        <React.Fragment key={`step-${j}`}>
          <div className={classRenderer(singleStep.step)} onClick={() => handleClick(singleStep.step)}>
            <span>{singleStep.name}</span>
            <div className="step-step">{singleStep.step}</div>
          </div>
          {j < steps.length - 1 ? <FontAwesomeIcon icon={faArrowRightLong} className={`arrow ${arrowClassRenderer(singleStep.step)}`} /> : null}
        </React.Fragment>
      ))}
    </div>
  );
}

export default WithContext(StepCounter);
