import dayjs from "dayjs";
import React, { Dispatch } from "react";
import Calendar from "../../components/Calendar/Calendar";
import { State } from "../../context/types";
import WithCart from "../../hoc/WithCart";
import WithCheckRouteValidity from "../../hoc/WithCheckRouteValidity";
import WithContext from "../../hoc/WithContext";
import WithLayout from "../../hoc/WithLayout";
import WithNextStepButton from "../../hoc/WithNextStepButton";
import { Action } from "../../reducers";
import "./../BreakfastsListScreen/BreakfastsListScreen.scss";
import "./ScheduleScreen.scss";
import "./../../scss/global.scss";
import AdjustHour from "../../components/AdjustHour/AdjustHour";

export const dateAlreadyClicked = (dates: Date[], date: Date) => dates.some((d) => dayjs(date).isSame(dayjs(d), "day"));
const datesExcept = (dates: Date[], date: Date) => dates.filter((d) => !dayjs(date).isSame(dayjs(d), "day"));

function ScheduleScreen({ state, dispatch }: { state: State; dispatch: Dispatch<Action> }) {
  const days = state.deliverAt.days;

  const setDays = (days: Date[]): void => {
    dispatch({ type: "SET_DELIVER_DAYS", payload: days });
  };

  const onClickDay = (date: Date): void => {
    if (dateAlreadyClicked(days, date)) {
      setDays(datesExcept(days, date));
    } else {
      setDays([...days, date].sort((a, b) => a.getTime() - b.getTime()));
    }
  };

  return (
    <div className="schedule-screen">
      <div className="schedule-screen-top">
        <h3 className="title schedule-title">Wybierz dni i czas dostawy</h3>
        <AdjustHour />
      </div>
      <Calendar days={days} onClickDay={onClickDay} />
    </div>
  );
}

export default WithContext(WithCart(WithNextStepButton(WithLayout(WithCheckRouteValidity(ScheduleScreen)))));
