import { CartItem, Breakfast, Variant, DeliverAt } from "../context/types";
import { OrderItem } from "../screens/AdjustScreen/AdjustScreen";

class OrderBuilder {
  private cart: CartItem[];
  private order: OrderItem[];

  constructor(cart: CartItem[], order: OrderItem[]) {
    this.cart = cart;
    this.order = order;
  }

  public addToOrder(breakfast: Breakfast, variant: Variant): OrderItem[] {
    if (!this.order.length) return [];

    const initialQuantity = this.order[0].order.length ? 0 : 1;

    this.order = this.order.map((item: { day: Date; order: CartItem[] }) => ({ day: item.day, order: [...item.order, { breakfast, variant, quantity: initialQuantity }] }));

    return this.order;
  }

  public removeFromOrder(variantId: number): OrderItem[] {
    this.order = this.order.map((item: { day: Date; order: CartItem[] }) => ({ day: item.day, order: item.order.filter((item) => item.variant.id !== variantId) }));

    return this.order;
  }

  public buildOrderForFirstTime(deliverAt: DeliverAt): any[] {
    const builtOrder: any[] = [];

    deliverAt.days.forEach((day) => {
      builtOrder.push({ day: day, order: this.cart });
    });

    return builtOrder;
  }

  public alterDays(deliverAt: DeliverAt): OrderItem[] {
    const alteredOrder: OrderItem[] = [];

    deliverAt.days.forEach((day) => {
      const common: any = this.order.filter((item) => item.day.getTime() === day.getTime())[0];

      if (common) {
        alteredOrder.push(common);
      } else {
        alteredOrder.push({ day: day, order: this.cart });
      }
    });

    return alteredOrder;
  }

  public buildSameDayEveryday(): OrderItem[] {
    const firstDayOrder = this.order[0].order;

    this.order = this.order.map((item: { day: Date; order: CartItem[] }) => ({ day: item.day, order: firstDayOrder }));

    return this.order;
  }

  public setCart(cart: CartItem[]): void {
    this.cart = cart;
  }
}

export default OrderBuilder;
