import React, { Dispatch } from "react";
import { State } from "../../context/types";
import WithContext from "../../hoc/WithContext";
import { Action } from "../../reducers";

function AdjustHour({ state, dispatch }: { state: State; dispatch: Dispatch<Action> }) {
  const setDeliverTime = (mode: number): void => {
    dispatch({ type: "SET_DELIVER_HOUR", payload: mode });
  };

  return (
    <div className="flex gap center">
      <button onClick={() => setDeliverTime(1)} className={`button outline-secondary big ${state.deliverAt.hour === 1 ? "active" : ""}`}>
        7:00 - 8:00
      </button>
      <button onClick={() => setDeliverTime(2)} className={`button outline-secondary big ${state.deliverAt.hour === 2 ? "active" : ""}`}>
        8:30 - 9:30
      </button>
    </div>
  );
}

export default WithContext(AdjustHour);
