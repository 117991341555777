import React from "react";
import OrderDaysList from "../../components/OrderDaysList/OrderDaysList";
import { DeliverAt, State } from "../../context/types";
import WithContext from "../../hoc/WithContext";
import WithLayout from "../../hoc/WithLayout";
import WithNextStepButton from "../../hoc/WithNextStepButton";
import { OrderItem } from "../AdjustScreen/AdjustScreen";
import "./../../scss/global.scss";

function SummaryScreen({ state }: { state: State }) {
  const { order, deliverAt }: { order: OrderItem[]; deliverAt: DeliverAt } = state;

  return (
    <div className="summary-screen">
      <h3 className="title">Podsumowanie</h3>
      
      <div className="adjust-days-list">
        <OrderDaysList order={order} deliverAt={deliverAt} changeQuantity={null} hideNotOrdered={true} />
      </div>
    </div>
  );
}

export default WithContext(WithNextStepButton(WithLayout(SummaryScreen)));
