import WithContext from "../../hoc/WithContext";
import "./../../scss/global.scss";
import BreakfastListItem from "../../components/BreakfastListItem/BreakfastListItem";
import WithCart from "../../hoc/WithCart";
import WithNextStepButton from "../../hoc/WithNextStepButton";
import WithLayout from "../../hoc/WithLayout";
import "./BreakfastsListScreen.scss";
import { Breakfast, State } from "../../context/types";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../utils/Api";

function BreakfastsListScreen({ state }: { state: State }) {

  const { hotel } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const checkHotel = async () => {
      if (!hotel) navigate('/');
      try {
        const response = await api.checkLink(hotel as string);
        if (!response.status) navigate('/');
      } catch (error) {
        console.error(error);
      }

    }

    checkHotel();
  }, []);

  return (
    <>
      <h3 className="title">Wybierz śniadania</h3>
      <p className="subtitle">które chciałbyś zamówić i dodaj je do koszyka</p>
      <div className="breakfasts-screen-wrapper">
        <div className="breakfasts-screen-grid padded-list">
          {state.breakfasts.map((breakfast: Breakfast, j: number) =>
            <BreakfastListItem key={`breakfast-${j}`} breakfast={breakfast} />
          )}
        </div>
      </div>
    </>
  );
}

export default WithContext(WithCart(WithNextStepButton(WithLayout(BreakfastsListScreen))));
