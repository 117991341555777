import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./Popup.scss";

function AdjustHourPopup({ content, close }: { content: any; close: Function }) {
  const handleClick = (e: any) => {
    e.preventDefault();
    close();
  };

  return (
    <div className="popup-overlay" onClick={(e) => {handleClick(e)}}>
      <div className="popup-content">
        <div className="popup-close" onClick={(e) => handleClick(e)}>
          <FontAwesomeIcon icon={faTimes} className="popup-close-icon" />
        </div>
        {content}
      </div>
    </div>
  );
}

export default AdjustHourPopup;
