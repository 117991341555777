import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './LoaderScreen.scss'

function LoaderScreen() {
  return (
    <div className="loader">
      <FontAwesomeIcon icon={faSpinner} className="spinner" />
      <p>Ładowanie</p>
    </div>
  )
}

export default LoaderScreen