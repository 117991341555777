import React from 'react';
import ReactDOM from 'react-dom/client';
import AppContextProvider from './context/AppContextProvider';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter, Route, Routes,
} from "react-router-dom";  
import Page404 from './screens/Page404/Page404';
import './scss/global.scss';
import BreakfastScreen from './screens/BreakfastScreen/BreakfastScreen';
import CartScreen from './screens/CartScreen/CartScreen';
import BreakfastsListScreen from './screens/BreakfastsListScreen/BreakfastsListScreen';
import ScheduleScreen from './screens/ScheduleScreen/ScheduleScreen';
import AdjustScreen from './screens/AdjustScreen/AdjustScreen';
import SummaryScreen from './screens/SummaryScreen/SummaryScreen';
import OrderStatusScreen from './screens/OrderStatusScreen/OrderStatusScreen';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// pobieraj tylko śniadania w module laris api, kwestia łączenia produktów icebergowych z produktami goposa.

root.render(
  <React.StrictMode>
    <AppContextProvider>
      <ToastContainer/>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Page404/>}></Route>
          <Route path='/:hotel/:roomId' element={<BreakfastsListScreen/>}></Route>
          <Route path='/:hotel/:roomId/cart' element={<CartScreen/>}></Route>
          <Route path='/:hotel/:roomId/breakfast/:breakfastId/:variantId' element={<BreakfastScreen/>}></Route>
          <Route path='/:hotel/:roomId/schedule' element={<ScheduleScreen/>}></Route>
          <Route path='/:hotel/:roomId/adjust' element={<AdjustScreen/>}></Route>
          <Route path='/:hotel/:roomId/summary' element={<SummaryScreen/>}></Route>
          <Route path='/order/:orderId' element={<OrderStatusScreen/>}></Route>
        </Routes>
      </BrowserRouter>
    </AppContextProvider>
  </React.StrictMode>
);

reportWebVitals();
